import {
  createResource,
  postResource,
  patchResource,
} from '@resource/resource'

import { prefixAPI } from '@resource/api'

const CITIES_MODEL = 'cities-light'
const REVIEW_MODEL = 'reviews'
const RATING_MODEL = 'rating'
const BRANCHES_MODEL = 'organization/branch'
const SETTLEMENTS_MODEL = 'organization/settlement'
const ORGANIZATION_MODEL = 'organization'
const COUNTRIES_MODEL = 'region/country'
const BLOG_MODEL = 'blog'
const CAPTCHA_MODEL = 'captcha'
const TRIPS_MODEL = 'trips'

export const SEACRH_URL = prefixAPI('search/?search={search}&limit=12')
const LANG_CHANGE_URL = prefixAPI('language/change/{code}/')
const LANG_LIST_URL = prefixAPI('language/communication-list/')

export const SEACRH_V2_URL = prefixAPI('watson-search/?search={search}&limit=12{&filters*,limit_by_product,offset_by_product}')
export const SEACRH_V3_URL = prefixAPI('categories-grouped/search/?limit=12{&filters*,search}')
export const SEACRH_V3_PAGINATED_URL = prefixAPI('categories-grouped/found/?limit=12{&filters*,search,found_ids}')

export const CAPTCHA_CREATE_URL = prefixAPI('/create/', CAPTCHA_MODEL)

const ANSWERS_SEACRH_URL = prefixAPI('knowledge-base/articles/list/?search={search}&limit=12')
const SHARE_URL = prefixAPI('share-page-by-sms/')
const DELIVERIES_CITIES_LIST_URL = prefixAPI('payment-and-delivery/delivery/{id}/')
const FILE_EXTENTION_LIST_URL = prefixAPI('file-extention/list/')
const CURRENCY_LIST_URL = prefixAPI('currency/available/{?country_available}')
const PHONE_CODES_LIST_URL = prefixAPI('phone-code/list/')

const REVIEW_CREATE_URL = prefixAPI('/create/', REVIEW_MODEL)
const REVIEW_LIST_URL = prefixAPI('/list/{?id,type,offset}', REVIEW_MODEL)
const BRANDS_LIST_URL = prefixAPI('/list/?limit=100{&settlement,country_available}', BRANCHES_MODEL)
const SETTLEMENTS_LIST_URL = prefixAPI('/list/?limit=100{&settlement,country_available}', SETTLEMENTS_MODEL)
const BRANDS_CATEGORIES_LIST_URL = prefixAPI('/categories/list/?limit=100', BRANCHES_MODEL)
const RATING_CREATE_UPDATE_URL = prefixAPI('/create-update/', RATING_MODEL)

const BLOG_PREVIEW_LIST_URL = prefixAPI('/preview-post/list/{?offset,limit}', BLOG_MODEL)

const TRIPS_RETRIEVE_URL = prefixAPI('/retrieve/{label}/', TRIPS_MODEL)
const TRIPS_POTENTIAL_PARTICIPANT_CREATE_URL = prefixAPI('/potential-participant/create/', TRIPS_MODEL)
const TRIPS_POTENTIAL_PARTICIPANT_UPDATE_URL = prefixAPI('/potential-participant/update/{id}/', TRIPS_MODEL)

const CITIES_URL = prefixAPI('/cities/', CITIES_MODEL)
const PAGES_URL = prefixAPI('pages{url}')

const COUNTRIES_LIST_URL = prefixAPI('-list/', COUNTRIES_MODEL)
const COUNTRY_CHANGE_URL = prefixAPI('-change/{code}/', COUNTRIES_MODEL)

export const searchResource = createResource(SEACRH_URL)
export const searchV2Resource = createResource(SEACRH_V2_URL)

export const ReviewCreate = createResource(REVIEW_CREATE_URL, postResource)
export const ReviewListResoure = createResource(REVIEW_LIST_URL)

export const RatingCreate = createResource(RATING_CREATE_UPDATE_URL, postResource)

export const BrandsListResoure = createResource(BRANDS_LIST_URL)
export const BrandsCategoriesListResoure = createResource(
  BRANDS_CATEGORIES_LIST_URL
)

export const SettlementsListResoure = createResource(SETTLEMENTS_LIST_URL)
export const CitiesListResoure = createResource(CITIES_URL)

export const PageShare = createResource(SHARE_URL, postResource)
export const DeliveryCitiesList = createResource(DELIVERIES_CITIES_LIST_URL)

export const asnwersSearchResource = createResource(ANSWERS_SEACRH_URL)

export const countriesListResource = createResource(COUNTRIES_LIST_URL)
export const countryChangeResource = createResource(COUNTRY_CHANGE_URL, postResource)

export const fileExtentionResource = createResource(FILE_EXTENTION_LIST_URL)

export const currencyListResource = createResource(CURRENCY_LIST_URL)
export const phoneCodeListResource = createResource(PHONE_CODES_LIST_URL)

export const blogPreviewListResource = createResource(BLOG_PREVIEW_LIST_URL)

const ORGANIZATION_SINGLE_URL = prefixAPI('/', ORGANIZATION_MODEL)
const SOCIAL_LINKS_URL = prefixAPI('/social-link/list/{?filters*}', ORGANIZATION_MODEL)
const ORGANIZATION_URL = prefixAPI('/list/{?country_available}', ORGANIZATION_MODEL)
const ORGANIZATION_CALL_CENTER_URL = prefixAPI('/call-center-contact/list/', ORGANIZATION_MODEL)
export const OrganizationListResource = createResource(ORGANIZATION_URL)
export const OrganizationResource = createResource(ORGANIZATION_SINGLE_URL)
export const OrganizationCallCenterResource = createResource(ORGANIZATION_CALL_CENTER_URL)
export const SocialLinksResource = createResource(SOCIAL_LINKS_URL)
export const PagesResource = createResource(PAGES_URL)

export const LangChangeResource = createResource(LANG_CHANGE_URL, postResource)
export const LangListResource = createResource(LANG_LIST_URL)

export const createCaptchaResource = createResource(CAPTCHA_CREATE_URL)
export const tripRetrieveResource = createResource(TRIPS_RETRIEVE_URL)
export const tripParticipantCreateResource = createResource(TRIPS_POTENTIAL_PARTICIPANT_CREATE_URL, postResource)
export const tripParticipantUpdateResource = createResource(TRIPS_POTENTIAL_PARTICIPANT_UPDATE_URL, patchResource)
