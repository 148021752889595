import {
  createResource,
  baseResource,
  postResource,
} from '@resource/resource'

import {
  friendlyUrlGenerator,
} from '@app/Filters/url'
import {
  friendlyUrlGenerator as UrlGenerator,
} from '@aspectus/vue-friendly'

import { prefixAPI } from '@resource/api'

export const BASE_AJAX_URL_POSTFIX = '/ajax/filters/'

export const order = {
  path: [
    'prefix',
    'label',
    'postfix',
    'filters',
  ],
  query: [
    'pagination_offset',
  ],
}

const ONLY_ATTRIBUTES_URL = prefixAPI('attributes/filter-list/')
export const onlyAttributesResource = createResource(ONLY_ATTRIBUTES_URL)

const PRICES_FILTERS_URL = prefixAPI('store/price-filter/list/')
export const pricesResource = createResource(PRICES_FILTERS_URL)

const ATTRIBUTES_URL = prefixAPI('store/filters/')
export const attributesResource = createResource(ATTRIBUTES_URL)

const BRANDS_ATTRIBUTES_URL = prefixAPI('store/brand-filters/{label}/')
export const brandsAttributesResource = createResource(BRANDS_ATTRIBUTES_URL)

const SALES_DETAIL_ATTRIBUTES_URL = prefixAPI('sales/product-filters/{label}/')
export const salesDetailAttributesResource = createResource(SALES_DETAIL_ATTRIBUTES_URL)

const TRIPS_DETAIL_ATTRIBUTES_URL = prefixAPI('trips/product-filters/{label}/')
export const tripsDetailAttributesResource = createResource(TRIPS_DETAIL_ATTRIBUTES_URL)

// bonus detail
const BONUS_DETAIL_ATTRIBUTES_URL = prefixAPI('bonus/product-filters/{label}/')
export const bonusDetailAttributesResource = createResource(BONUS_DETAIL_ATTRIBUTES_URL)

const CATEGORIES_URL = prefixAPI('store/categories/list/')
export const categoriesResource = createResource(CATEGORIES_URL)

const BRANDS_URL = prefixAPI('store/brands/list/{label}/')
export const brandsResource = createResource(BRANDS_URL)

const AVAILABLE_ATTRIBUTES_URL = '/catalog/ajax/available-attributes/'
export const availableAttributesResource =
  friendlyUrlGenerator([AVAILABLE_ATTRIBUTES_URL], baseResource, order)

const AVAILABLE_ATTRIBUTES_FOR_BRAND_URL = '/catalog/ajax/available-attributes/'
export const availableAttributesForBrandResource =
  friendlyUrlGenerator([AVAILABLE_ATTRIBUTES_FOR_BRAND_URL], baseResource, order)

export const bonus = {
  path: [
    'prefix',
    'label',
    'postfix',
    'filters',
  ],
  query: [
    'pagination_offset',
  ],
}

const AVAILABLE_ATTRIBUTES_FOR_BONUS_URL = '/ajax/available-attributes/'
export const availableAttributesForBonusResource =
  friendlyUrlGenerator(['/bonus', AVAILABLE_ATTRIBUTES_FOR_BONUS_URL], baseResource, bonus)

const PROTOCOL_FILTERS_LIST_URL_PREFIX = '/protocols/'
const BONUS_FILTERS_LIST_URL_PREFIX = '/bonuses/'

export const protocolListUrl =
  UrlGenerator([PROTOCOL_FILTERS_LIST_URL_PREFIX, BASE_AJAX_URL_POSTFIX], baseResource, order)

export const bonusListUrl =
  UrlGenerator([BONUS_FILTERS_LIST_URL_PREFIX, BASE_AJAX_URL_POSTFIX], baseResource, order)

const FILTERS_LIST_URL_PREFIX = '/catalog/'

export const productsListUrl =
  friendlyUrlGenerator([FILTERS_LIST_URL_PREFIX, BASE_AJAX_URL_POSTFIX], baseResource, order)

const SALES_FILTERS_LIST_URL = '/sales/'

const TRIPS_FILTERS_LIST_URL = '/trips/'

export const salesListUrl = UrlGenerator([SALES_FILTERS_LIST_URL, BASE_AJAX_URL_POSTFIX], baseResource, order)

export const tripsListUrl = UrlGenerator([TRIPS_FILTERS_LIST_URL, BASE_AJAX_URL_POSTFIX], baseResource, order)

const SALES_ATTRIBUTES_URL = prefixAPI('sales/filters/')
export const salesAttributesResource = createResource(SALES_ATTRIBUTES_URL)

const TRIPS_ATTRIBUTES_URL = prefixAPI('trips/filters/')
export const tripsAttributesResource = createResource(TRIPS_ATTRIBUTES_URL)

const DISTRIBUTOR_URL = prefixAPI('store/catalogs/distributor/{?offset,brand,category,limit}')
export const distributorCaralogResource = createResource(DISTRIBUTOR_URL)

const PARTNERS_FILTERS_LIST_URL = '/partners/'
export const partnersListUrl = UrlGenerator([PARTNERS_FILTERS_LIST_URL, BASE_AJAX_URL_POSTFIX], baseResource, order)

const PARTNERS_ATTRIBUTES_URL = prefixAPI('staff/partners/filters/')
export const partnersAttributesResource = createResource(
  PARTNERS_ATTRIBUTES_URL
)
const SALE_DETAIL_FILTERS_LIST_URL = '/sales/'
export const salesDetailUrl = friendlyUrlGenerator([SALE_DETAIL_FILTERS_LIST_URL, BASE_AJAX_URL_POSTFIX], baseResource, order)

const TRIPS_DETAIL_FILTERS_LIST_URL = '/trips/'
export const tripsDetailUrl = friendlyUrlGenerator([SALE_DETAIL_FILTERS_LIST_URL, BASE_AJAX_URL_POSTFIX], baseResource, order)

const BONUS_DETAIL_FILTERS_LIST_URL = '/bonuses/'
export const bonusDetailUrl = friendlyUrlGenerator([BONUS_DETAIL_FILTERS_LIST_URL, BASE_AJAX_URL_POSTFIX], baseResource, order)

const BRANDS_DETAIL_FILTERS_LIST_URL = '/brands/'
export const brandDetailUrl = friendlyUrlGenerator([BRANDS_DETAIL_FILTERS_LIST_URL, BASE_AJAX_URL_POSTFIX], baseResource, order)

const DISCOUNT_DETAIL_FILTERS_LIST_URL = '/discount/catalog/'
export const discountDetailUrl = friendlyUrlGenerator([DISCOUNT_DETAIL_FILTERS_LIST_URL, BASE_AJAX_URL_POSTFIX], baseResource, order)

const VACANCIES_DETAIL_FILTERS_LIST_URL = '/vacancies'
export const vacanciesListUrl = friendlyUrlGenerator([VACANCIES_DETAIL_FILTERS_LIST_URL, BASE_AJAX_URL_POSTFIX], baseResource, order)

const ATTACHMENTS_DETAIL_FILTERS_LIST_URL = '/attachments/'
export const attachmentsDetailUrl = UrlGenerator([ATTACHMENTS_DETAIL_FILTERS_LIST_URL, BASE_AJAX_URL_POSTFIX], baseResource, order)

const ATTACHMENTS_ATTRIBUTES_URL = prefixAPI('store/attachment-filters/')
export const attachmentAttributesResource = createResource(
  ATTACHMENTS_ATTRIBUTES_URL
)
const VARIANT_SPIN_URL = prefixAPI('store/variant/images-360/{id}/')
export const variantSpinResource = createResource(
  VARIANT_SPIN_URL
)

const specialOrder = {
  path: [
    'prefix',
    'postfix',
    'filters',
  ],
  query: [
    'pagination_offset',
    'categoryGP',
  ],
}

const BASE_AVAILABLE_ATTRIBUTES_URL = p => `${prefixAPI('/attributes/available/')}${p}/`

const VIDEO_FILTERS_LIST_URL = '/videos/'
export const videoUrl = UrlGenerator([VIDEO_FILTERS_LIST_URL, BASE_AJAX_URL_POSTFIX], baseResource, order)

const NEWS_FILTERS_LIST_URL_PREFIX = '/news/'

export const blogUrl =
  UrlGenerator([NEWS_FILTERS_LIST_URL_PREFIX, BASE_AJAX_URL_POSTFIX], baseResource, order)

const VACANCIES_FILTERS_LIST_URL_PREFIX = '/vacancies'

export const vacanciesUrl =
  UrlGenerator([VACANCIES_FILTERS_LIST_URL_PREFIX, BASE_AJAX_URL_POSTFIX], baseResource, order)

export const BlogAvailableFiltersResource = UrlGenerator([BASE_AVAILABLE_ATTRIBUTES_URL('Post')], baseResource, order)
export const VideoAvailableFiltersResource = UrlGenerator([BASE_AVAILABLE_ATTRIBUTES_URL('VideoPost')], baseResource, order)
export const SpecialOfferAvailableFiltersResource = UrlGenerator([BASE_AVAILABLE_ATTRIBUTES_URL('SpecialOffer')], baseResource, specialOrder)
export const AttachmentAvailableFiltersResource = UrlGenerator([BASE_AVAILABLE_ATTRIBUTES_URL('ProductAttachment')], baseResource, order)
export const ArticleAvailableFiltersResource = UrlGenerator([BASE_AVAILABLE_ATTRIBUTES_URL('Article')], baseResource, order)
export const PartnersAvailableFiltersResource = UrlGenerator([BASE_AVAILABLE_ATTRIBUTES_URL('Profile')], baseResource, order)
export const SalesAvailableFiltersResource = UrlGenerator([BASE_AVAILABLE_ATTRIBUTES_URL('Campaign')], baseResource, order)
export const TripsAvailableFiltersResource = UrlGenerator([BASE_AVAILABLE_ATTRIBUTES_URL('Campaign')], baseResource, order)
export const VacancyAvailableFiltersResource = UrlGenerator([BASE_AVAILABLE_ATTRIBUTES_URL('JobVacancy')], baseResource, order)
export const ProtocolAvailableFiltersResource = UrlGenerator([BASE_AVAILABLE_ATTRIBUTES_URL('Protocol')], baseResource, order)
export const DiscountAvailableFiltersResource = UrlGenerator([BASE_AVAILABLE_ATTRIBUTES_URL('Product'), 'discount/'], baseResource, order)
export const BonusAvailableFiltersResource = UrlGenerator([BASE_AVAILABLE_ATTRIBUTES_URL('Product'), 'bonus/'], baseResource, order)

const VIDEO_ATTRIBUTES_URL = prefixAPI('blog/video-post/filters/')
export const videoAttributesResource = createResource(
  VIDEO_ATTRIBUTES_URL
)
// const BLOG_ATTRIBUTES_URL = prefixAPI('blog/post/filters/')
// export const blogAttributesResource = createResource(
//   BLOG_ATTRIBUTES_URL
// )
const BLOG_ATTRIBUTES_LIST_URL_PREFIX = prefixAPI('/blog/post/')
const BLOG_ATTRIBUTES_LIST_URL_POSTFIX = '/filters/'
export const blogAttributesResource =
  UrlGenerator([BLOG_ATTRIBUTES_LIST_URL_PREFIX, BLOG_ATTRIBUTES_LIST_URL_POSTFIX], baseResource, order)

const offers_order = {
  path: [
    'prefix',
    'category',
    'postfix',
    'filters',
  ],
  query: [
    'pagination_offset',
  ],
}

const OFFERS_LIST_CARDS_URL_PREFIX = '/special-offers/category/'
const OFFERS_LIST_FILTERS_URL_URL_POSTFIX = 'ajax/filters/'
export const offersListCardsResource =
  UrlGenerator([OFFERS_LIST_CARDS_URL_PREFIX, OFFERS_LIST_FILTERS_URL_URL_POSTFIX], baseResource, offers_order)

const OFFERS_ATTRIBUTES_URL = prefixAPI('special_offer/filters/{label}/')
export const offersAttributesResource = createResource(OFFERS_ATTRIBUTES_URL)

const ATTACHMENTS_AND_VIDEO_LIST_URL = prefixAPI('store/product/attachment-and-video/{id}/')
export const attachmentAndVideoUrl = createResource(ATTACHMENTS_AND_VIDEO_LIST_URL)

const KNOWLEDGE_BASE_CARDS_URL = '/knowledge/'
export const knowledgeBaseCardsResource = UrlGenerator([KNOWLEDGE_BASE_CARDS_URL, BASE_AJAX_URL_POSTFIX], baseResource, order)

const KNOWLEDGE_BASE_FILTERS_URL = prefixAPI('knowledge/articles/filters/')
export const knowledgeBaseFiltersResource = createResource(KNOWLEDGE_BASE_FILTERS_URL)

const VACANCIES_FILTERS_URL = prefixAPI('totis-work/job-vacancy/filters/')
export const jobVacancyFiltersResource = createResource(VACANCIES_FILTERS_URL)

const PROTOCOL_FILTERS_URL = prefixAPI('protocol/filters/')
export const protocolFiltersResource = createResource(PROTOCOL_FILTERS_URL)

const DISCOUNT_FILTERS_URL = prefixAPI('store/filters/discount/')
export const discountFiltersResource = createResource(DISCOUNT_FILTERS_URL)

const BONUS_FILTERS_URL = prefixAPI('bonus/filters/')
export const bonusFiltersResource = createResource(BONUS_FILTERS_URL)
